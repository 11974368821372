import { DOMAIN } from "../config/env";
import api from "./apiClient";

export async function getDossiersDashBoard() {
  let dossiers = {};
  await api.get(`${DOMAIN.URL_API}dossiers-dashBoard`).then(async (res) => {
    dossiers = await res.data;
  });
  return dossiers;
}
export async function getArticlesByContrat(con_code) {
  let bordereaux = {};
  await api.get(`${DOMAIN.URL_API}articles/${con_code}`).then((res) => {
    bordereaux = res.data;
  });
  return bordereaux;
}
export async function getClotureDossierArticle(Dossier_Code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}cloture-dossier-article/${Dossier_Code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}

export async function toggleClotureArticle(Dossier_Code) {
  const data = {
    Dossier_Code: Dossier_Code,
  };

  await api.put(DOMAIN.URL_API + "toggle-dossier", { ...data });
}
export async function rappelArticle(Dossier_Code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}rappel-cloture-article/${Dossier_Code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}
export async function rappelArticleAll() {
  let bordereaux = {};
  await api.get(`${DOMAIN.URL_API}rappel-cloture-article-all`).then((res) => {
    bordereaux = res.data;
  });
  return bordereaux;
}
export async function deleteCreationDossier(Dossier_Code) {
  let result = {};
  await api
    .delete(`${DOMAIN.URL_API}delete-creation-dossier/${Dossier_Code}`)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      console.error("Erreur lors du nettoyage des articles : ", err);
      throw err;
    });

  return result;
}
/**
 * Import les déclarations de rfa
 * @param {*} data
 * @param {*} dossier_code
 */
export async function importArticle(file, dossier_code) {
  let total = 0; // Total importé
  let errors = { lignes: [], nb_lignes: 0 }; // Erreurs lors de l'importation
  console.log("XXX : ".dossier_code);
  const data = {
    importfile: file,
    dossier_code: dossier_code,
  };
  await api
    .post(
      DOMAIN.URL_API + "import-article",
      { ...data },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      total = res.data["sum"];
    })
    .catch((err) => {
      console.log(err);
      const errs = err.response.data["errors"];
      errors = {
        ...errors,
        lignes: errs.lignes,
        nb_lignes: errs.nb_lignes,
      };
      total = err.response.data["sum"];
    });
  return { total: total, errors: errors };
}
