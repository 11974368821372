import React from "react";
import "./style.scss";
import Button from "../../../../../button/Button";
import { deleteCreationDossier } from "../../../../../../../services/articles_api";

const ARTdeletecreationCard = ({
  hide,
  dossier,
  refreshData,
  toggleNotification,
}) => {
  console.log(dossier);
  const handleValidation = () => {
    deleteCreationDossier(dossier.Dossier_Code)
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de la clôture du dossier:", error);
      });
    refreshData();
    hide();
    toggleNotification();
  };

  return (
    <>
      <div className="downlist-card">
        <p className="downlist-card__body">
          Une fois valider vous ne pourrez pas revenir en arrière.
        </p>
        <div className="downlist-card__action">
          <Button
            libelle="Valider"
            color="excel-btn"
            onClick={handleValidation}
          />
          <Button libelle="Annuler" color="color-neutral" onClick={hide} />
        </div>
      </div>
    </>
  );
};

export default ARTdeletecreationCard;
