import React, { useState } from "react";
import "./style.scss";
import Button from "../../../../../button/Button";
import { getClotureDossierArticle } from "../../../../../../../services/articles_api";

const ARTclotureCard = ({ hide, dossier, refreshData, toggleNotification }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleValidation = () => {
    setIsLoading(true);
    getClotureDossierArticle(dossier.Dossier_Code)
      .then(() => {
        refreshData();
        hide();
        toggleNotification();
      })
      .catch((error) => {
        console.error("Erreur lors de la clôture du dossier:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="downlist-card">
        <p className="downlist-card__body">
          Une fois validé, vous ne pourrez pas revenir en arrière.
        </p>
        <div className="downlist-card__action">
          <Button
            libelle="Valider"
            color="excel-btn"
            onClick={handleValidation}
            loading={isLoading}
          />
          <Button libelle="Annuler" color="color-neutral" onClick={hide} />
        </div>
      </div>
    </>
  );
};

export default ARTclotureCard;
