import React, { useState, useCallback, useEffect } from "react";
import MissingDataCard from "../../../components/ui/cards/MissingDataCard";
import LoaderApp from "../../../components/ui/loaders/LoaderApp";
import useContrat from "../../../hooks/useContrat";
import { getArticlesByContrat } from "../../../services/articles_api";
import ARTactionsList from "./../../../components/_rfa/_Article/ARTactionsList";
import ARTarticleTable from "../../../components/ui/tables/_RFA/_Article/ARTarticleTable";
import BasicNotification from "../../../components/ui/notifications/BasicNotification";
import "./style.scss";

const Article = () => {
  const { contrat } = useContrat();
  const [loading, setLoading] = useState(true);
  const [listArticles, setListArticles] = useState({});
  const [dossier, setDossier] = useState({});
  const [refreshing, setRefreshing] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const toggleNotification = () => setShowNotification(!showNotification);

  /**
   * Recupere les articles en fonction du contrat
   */
  const getArticles = useCallback(async () => {
    try {
      const data = await getArticlesByContrat(contrat?.con_code);
      setListArticles(data.articles);
      setDossier(data.dossier);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [contrat]);

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  useEffect(() => {
    if (refreshing) {
      getArticles();
    }
  }, [refreshing, getArticles]);

  if (loading) return <LoaderApp />;
  if (!contrat) return null;

  if (!dossier) {
    return (
      <MissingDataCard>
        Vous n'avez pas de dossier de mise à jour pour le moment.
      </MissingDataCard>
    );
  }

  return (
    <div className="declaration animate__animated animate__fadeIn">
      <header className="article__head">
        <h3 className="">
          Articles
          <br />
          {contrat.con_nom}
        </h3>

        <section className="declaration__body">
          {dossier.statut_cloture !== 1 && (
            <ARTactionsList
              refreshData={() => setRefreshing(true)}
              refreshContratRFA={contrat}
              dossier={dossier}
              toggleNotification={toggleNotification}
            />
          )}

          <section>
            <ARTarticleTable article={listArticles} />
          </section>
        </section>

        {showNotification && (
          <BasicNotification
            isShowing={showNotification}
            hide={toggleNotification}
            text="Le dossier a été clôturé avec succès"
          />
        )}
      </header>
    </div>
  );
};

export default Article;
