import React from "react";
import "./style.scss";

import ARTdownloadListModal from "../../../ui/modals/_RFA/_Article/ARTdownloadListModal";
import ARTdownloadNomenclatureModal from "../../../ui/modals/_RFA/_Article/ARTdownloadNomenclatureModal";
import ARTimportListModal from "../../../ui/modals/_RFA/_Article/ARTimportListModal";
import ARTclotureModal from "../../../ui/modals/_RFA/_Article/ARTclotureModal";
import ARTdeletecreationModal from "../../../ui/modals/_RFA/_Article/ARTdeletecreationModal";

const ARTactionsList = ({ refreshData, dossier, toggleNotification }) => {
  return (
    <div className={`declaration-actions`}>
      <div className="btn-list">
        {!dossier.statut_statut_cloture && (
          <>
            <ARTdownloadListModal dossier={dossier} />
            <ARTimportListModal dossier={dossier} refreshData={refreshData} />
            <ARTdeletecreationModal
              dossier={dossier}
              refreshData={refreshData}
              toggleNotification={toggleNotification}
            />
            <ARTclotureModal
              dossier={dossier}
              refreshData={refreshData}
              toggleNotification={toggleNotification}
            />
            <ARTdownloadNomenclatureModal />
          </>
        )}
      </div>
    </div>
  );
};

export default ARTactionsList;
