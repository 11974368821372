import React, { useState, useEffect, useMemo } from "react";
import style from "./style.scss";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import { makeStyles } from "@mui/styles";
import ADMclotureArticleModal from "../../../../modals/_Administration/ADMclotureArticleModal";
import ADMrappelArticleModal from "../../../../modals/_Administration/ADMrappelArticleModal";
import ADMrappelArticleAllModal from "../../../../modals/_Administration/ADMrappelArticleAllModal";

const useStyles = makeStyles({
  root: {
    color: style.colorText,
  },
  columnHeaders: {
    backgroundColor: style.colorPrimaryVariant,
    color: style.colorTextVariant,
    borderBottom: `1px solid rgba(${style.colorPrimary}, 0.3)`,
    "&:hover": {
      backgroundColor: `rgba(${style.colorPrimary}, 0.2)`,
    },
  },
  row: {
    border: `1px solid rgba(${style.colorNeutral}, 0.1)`,
    fontSize: "0.8em",
    fontWeight: 400,
  },
  selectedRow: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.5)`,
  },
  iconButton: {
    color: style.colorPrimary,
    "& .MuiSvgIcon-root": {
      color: style.colorPrimary,
    },
  },
  footerContainer: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.7)`,
    color: style.colorTextVariant,
  },
  pagination: {
    color: style.colorTextVariant,
  },
});

const ADMDossiersTable = ({ data, refreshData }) => {
  const [rows, setRows] = useState([]);
  const [pageSize] = useState(100);
  const [searchQuery, setSearchQuery] = useState("");
  const [showWithDossierOnly, setShowWithDossierOnly] = useState(false);
  const classes = useStyles();

  // Colonnes de la table
  const columns = [
    {
      field: "Dossier_Code",
      headerName: "Dossier",
      width: 70,
    },
    {
      field: "con_code",
      headerName: "Code",
      width: 70,
    },
    {
      field: "con_nom",
      headerName: "Fournisseur",
      width: 200,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "che_nom",
      headerName: "Chef produits",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "date_deposer",
      headerName: "Date d'ouverture",
      width: 130,
      renderCell: (params) => {
        const formatDate = (dateString) => {
          if (!dateString) return "-"; // Si la date est nulle ou non définie
          // Essayer de convertir la date
          try {
            const dateParts = dateString.split("-"); // Si le format est AAAA-MM-JJ
            const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            return formattedDate;
          } catch (error) {
            return "Date invalide"; // Gérer les erreurs
          }
        };

        return (
          <span className="custom-cell" title={params.value}>
            {formatDate(params.value)}
          </span>
        );
      },
    },
    {
      field: "date_cloture",
      headerName: "Date de cloture",
      width: 130,
      renderCell: (params) => {
        const formatDate = (dateString) => {
          try {
            const dateParts = dateString.split("-");
            if (dateParts.length === 3) {
              return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Format JJ/MM/AAAA
            }
            return "-"; // Si le format est incorrect
          } catch (error) {
            return "-"; // Gérer d'éventuelles erreurs
          }
        };

        return (
          <span className="custom-cell" title={params.value}>
            {formatDate(params.value)}
          </span>
        );
      },
    },
    {
      field: "date_rappel",
      headerName: "Dernier rappel",
      width: 130,
      renderCell: (params) => {
        const formatDate = (dateString) => {
          if (!dateString) return "-"; // Si la date est null, undefined ou vide
          try {
            const dateParts = dateString.split("-");
            if (dateParts.length === 3) {
              return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Format JJ/MM/AAAA
            }
            return "-"; // Si le format est incorrect
          } catch (error) {
            return "-"; // Gérer d'éventuelles erreurs
          }
        };

        return (
          <span className="custom-cell" title={params.value}>
            {formatDate(params.value)}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 255,
      getActions: ({ id }) => {
        const row = rows.find(
          (row) => parseInt(row.Dossier_Code) === parseInt(id)
        );
        return [
          <>
            {row.statut_cloture === null ? (
              <span>Pas de dossier</span>
            ) : (
              <>
                {!row.statut_cloture && <ADMrappelArticleModal data={row} />}
                <ADMclotureArticleModal data={row} refreshData={refreshData} />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  useEffect(() => {
    const transformedData = Object.keys(data).map((key) => {
      const item = data[key];
      return {
        Dossier_Code: item.Dossier_Code,
        con_code: item.con_code,
        con_nom: item.contrat?.con_nom,
        date_deposer: item.date_deposer,
        date_cloture: item.date_cloture,
        date_rappel: item.date_rappel,
        che_nom: item.contrat?.chef_produit?.che_nom,
        statut_cloture: item.statut_cloture,
      };
    });
    setRows(transformedData);
  }, [data]);

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      // Si la case est cochée, n'afficher que les dossiers avec statut_cloture === 0
      if (showWithDossierOnly) {
        return row.statut_cloture === 0;
      }

      // Filtrer en fonction de la requête de recherche
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        return Object.values(row).some(
          (value) =>
            value != null && value.toString().toLowerCase().includes(query)
        );
      }

      // Sinon, inclure toutes les lignes
      return true;
    });
  }, [rows, showWithDossierOnly, searchQuery]);

  return (
    <>
      <div className="articles-rfa-table">
        <div className="filters">
          <input
            type="text"
            className="search-input"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <ADMrappelArticleAllModal />
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkbox-input"
              checked={showWithDossierOnly}
              onChange={(e) => setShowWithDossierOnly(e.target.checked)}
            />
            <span className="custom-checkbox" />
            Afficher uniquement les dossiers non cloturés
          </label>
        </div>
        <DataGrid
          classes={{
            root: classes.root,
            columnHeaders: classes.columnHeaders,
            cell: classes.cell,
            footerContainer: classes.footerContainer,
            pagination: classes.pagination,
            header: classes.header,
            row: classes.row,
            iconButton: classes.iconButton,
          }}
          rows={filteredRows}
          columns={columns.map((column) => ({
            ...column,
            cellClassName: ({ row }) => {
              return row.statut_cloture ? "declared-row" : "";
            },
          }))}
          getRowId={(row) => row.Dossier_Code}
          components={{
            Pagination: CustomPagination,
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMDossiersTable;
