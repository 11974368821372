import React, { useState, useEffect } from "react";
import "./style.scss";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import ADMportalLinkModal from "../../../modals/_Administration/ADMportalLinkModal";
import EditEmailModal from "./EditEmailModal";
import AddUser from "./AddUserModal";
import UserContrat from "./UserContrat";
import ResetMDP from "./ResetMDP";
import { formatDate } from "../../../../../utils/format";

const ADMfournisseursTable = ({ data, refreshData }) => {
  const [rows, setRows] = useState([]);
  const [pageSize] = useState(100);
  const [rowModesModel, setRowModesModel] = useState({});
  const [email] = useState("");

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow) => {
    console.log(newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.email === newRow.email ? updatedRow : row)));
    // update d'un user + mise à jour
    const doUpdateFournisseur = async (data) => {
      try {
        console.log(data, email);

        // await updateFournisseur(data);
        refreshData();
      } catch (error) {
        console.log(error);
      }
    };
    doUpdateFournisseur(newRow);
    return updatedRow;
  };

  const columns = [
    {
      field: "name",
      headerName: "Nom",
      width: 250,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 260,
      renderCell: (params) => {
        return (
          <div className="email-cell">
            <span className="custom-cell" title={params.value}>
              {params.value}
            </span>
            <EditEmailModal
              email={params.row.email}
              id={params.row.id}
              refreshData={refreshData}
            />
          </div>
        );
      },
    },
    {
      field: "activity",
      headerName: "Activité",
      width: 100,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {!!parseInt(params.value) ? "actif" : "inactif"}
          </span>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Créé le",
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "updated_at",
      headerName: "Modifié le",
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 270,
      cellClassName: "actions",

      getActions: (params) => {
        const row = rows.find((r) => r.id === params.id);

        return [
          <ADMportalLinkModal email={row?.email} />,
          <UserContrat userId={row?.id} />,
          <ResetMDP userId={row?.id} />,
        ];
      },
    },
  ];

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <div className="fournisseurs-table">
        <div className=" add-button">
          <AddUser refreshData={refreshData} />
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Pagination: CustomPagination,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          autoPageSize
          pagination
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMfournisseursTable;
