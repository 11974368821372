import React from "react";
import useModal from "../../../../../../hooks/useModal";
import Button from "../../../../button/Button";
import Modal from "../../../Modal";
import ARTdeletecreationCard from "./ARTdeletecreationCard";

const ARTdeletecreationModal = ({
  dossier,
  refreshData,
  toggleNotification,
}) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Supprimé les créations"
        onClick={() => modalState.toggleModal()}
        color="color-danger"
      />
      <Modal
        modalState={modalState}
        title="Voulez vous supprimé tout les articles en créations ? "
        color="excel-modal"
      >
        <ARTdeletecreationCard
          hide={modalState.toggleModal}
          dossier={dossier}
          refreshData={refreshData}
          toggleNotification={toggleNotification}
        />
      </Modal>
    </>
  );
};

export default ARTdeletecreationModal;
