import { DOMAIN } from "../config/env";
import api from "./apiClient";

// Fetch contracts associated with a specific user by their user ID
export async function getUserContracts(userId) {
  try {
    const response = await api.get(`${DOMAIN.URL_API}users/${userId}/contrats`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user contracts: ", error);
    // Handle the error appropriately
    throw error;
  }
}
export const addUser = async (data) => {
  try {
    const response = await api.post(`${DOMAIN.URL_API}user/ajouter`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error : error;
  }
};
// Fetch all available contracts
export async function getAllContracts() {
  try {
    const response = await api.get(`${DOMAIN.URL_API}contrats`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all contracts: ", error);
    // Handle the error appropriately
    throw error;
  }
}

// Add a contract to a user
export async function addContractToUser(userId, contract) {
  try {
    const response = await api.post(
      `${DOMAIN.URL_API}users/${userId}/assign-contract`,
      { contractId: contract.con_code } // Assuming you need to send the contract code
    );
    return response.data;
  } catch (error) {
    console.error("Error adding contract to user: ", error);
    // Handle the error appropriately
    throw error;
  }
}

// Remove a contract from a user
export async function removeContractFromUser(userId, contractId) {
  try {
    const response = await api.delete(
      `${DOMAIN.URL_API}users/${userId}/remove-contract/${contractId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error removing contract from user: ", error);
    // Handle the error appropriately
    throw error;
  }
}

/**
 * Récupères tous les users ayant pour role le role fournisseur
 */
export async function getFournisseurs() {
  let fournisseurs = {};
  await api.get(`${DOMAIN.URL_API}user-fournisseur`).then(async (res) => {
    fournisseurs = await res.data;
  });
  return fournisseurs;
}

export async function updateFournisseur(data) {
  await api.put(`${DOMAIN.URL_API}user-information`, { ...data });
}
export async function updateEmail(userId, newEmail) {
  await api.put(`${DOMAIN.URL_API}user-update-email`, {
    id: userId,
    new_email: newEmail,
  });
}
export async function mailPortail(email) {
  const data = {
    email: email,
  };
  await api.post(`${DOMAIN.URL_API}user/portal-link`, { ...data });
}
// Reset password user
export async function resetPassword(userId) {
  try {
    const response = await api.post(
      `${DOMAIN.URL_API}users/${userId}/reset-password`
    );
    return response.data;
  } catch (error) {
    console.error("Error adding contract to user: ", error);
    throw error;
  }
}
