import React from "react";
import useModal from "../../../../../../hooks/useModal";
import useNotification from "../../../../../../hooks/useNotification";
import { resetPassword } from "../../../../../../services/user_apis";
import ValidationModal from "../../../../modals/ValidationModal";
import PasswordIcon from "../../../../icons/PasswordIcon";
import Button from "../../../../button/Button";
import BasicNotification from "../../../../notifications/BasicNotification";

const ResetMDP = ({ userId }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  const handleResetMDP = async () => {
    try {
      const response = await resetPassword(userId);

      if (response.success) {
        toggleNotification();
      } else {
        console.error(
          "Échec de la réinitialisation du mot de passe :",
          response.message
        );
        alert(
          "Une erreur est survenue lors de la réinitialisation du mot de passe."
        );
      }
    } catch (error) {
      console.error(
        "Erreur lors de la réinitialisation du mot de passe :",
        error
      );
      alert("Une erreur inattendue est survenue. Veuillez réessayer.");
    } finally {
      // Fermer la modal après l'action
      modalState.toggleModal();
    }
  };

  return (
    <>
      <Button
        libelle=" "
        onClick={() => modalState.toggleModal()}
        color="color-neutral"
        icon={<PasswordIcon />}
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleResetMDP()}
        title="Réinitialiser Mot de passe"
      >
        <div className="cloture-declaration-text">
          Veuillez confirmer pour réinitialiser le mot de passe du fournisseur{" "}
        </div>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Le mot de passe a bien été réinitialisé"
      />
    </>
  );
};

export default ResetMDP;
